import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { ElLoading } from 'element-plus'
import { useDataLoader } from "./data-loader";

export function useAdminTable(getterName) {
  const store = useStore()

  const { tableData, isLoading } = useDataLoader(getterName)

  const selectedEditItem = ref({})
  const editModal = ref(false)
  const addModal = ref(false)
  const title = computed(() => store.getters['data/titles'].find(item => item.category === getterName)?.title)
  const titleID = computed(() => store.getters['data/titles'].find(item => item.category === getterName)?.id)

  const editTitle = ref(false)

  const loading = ref(null)

  const changeTitle = async (category, titleValue) => {
    loading.value = ElLoading.service({ fullscreen: true, customClass: 'app-loader' })
    titleValue = titleValue.trim()
    await store.dispatch('data/changeTitle', { category, title: titleValue, id: titleID.value })
    editTitle.value = false
    loading.value.close()
  }

  const changeElementData = async data => {
    loading.value = ElLoading.service({ fullscreen: true, customClass: 'app-loader' })
    await store.dispatch('data/editItem', { data, category: getterName })
    editModal.value = false
    loading.value.close()
  }

  const addElement = async data => {
    loading.value = ElLoading.service({ fullscreen: true, customClass: 'app-loader' })
    await store.dispatch('data/addItem', { data, category: getterName })
    addModal.value = false
    loading.value.close()
  }

  const removeElement = async data => {
    loading.value = ElLoading.service({ fullscreen: true, customClass: 'app-loader' })
    await store.dispatch('data/removeItem', { id: data.id, category: getterName })
    addModal.value = false
    loading.value.close()
  }

  return {
    selectedEditItem,
    editModal,
    addModal,
    addElement,
    removeElement,
    title,
    editTitle,
    changeTitle,
    editElement: item => {
      selectedEditItem.value = item
      editModal.value = true
    },
    tableData,
    changeElementData,
    isLoading
  }
}