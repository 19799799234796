<template>
  <app-modal
    title="Редактирование"
    :visible="visible"
    action
    @close="$emit('close')"
    @action="save"
  >
    <div class="form-item input">
      <label for="itemName">Название</label>
      <input
        type="text"
        id="itemName"
        :value="item.name"
        ref="name"
        placeholder="Название"
      />
    </div>

    <div class="form-item input">
      <label for="place">Место</label>
      <input
        type="number"
        id="place"
        :value="item.place"
        ref="place"
        placeholder="Место"
      />
    </div>

    <div v-if="type !== 'book'" class="form-item input">
      <label for="date">Дата выхода</label>
      <input
        type="text"
        id="date"
        :value="item.release"
        ref="release"
        placeholder="Дата выхода"
      />
    </div>

    <div v-if="type === 'film' || type === 'music'" class="form-item input">
      <label for="country">Страна</label>
      <input
        type="text"
        id="country"
        :value="item.country"
        ref="country"
        placeholder="Страна"
      />
    </div>

    <div v-if="type !== 'film'" class="form-item input">
      <label for="points">Оценка</label>
      <input
        type="text"
        id="points"
        :value="item.points"
        ref="points"
        placeholder="Оценка"
      />
    </div>

    <div class="form-item input">
      <label for="genre">Жанр</label>
      <input
        type="text"
        id="genre"
        :value="item.genre"
        ref="genre"
        placeholder="Жанр"
      />
    </div>

    <div class="form-item input">
      <label>Шкала объективности</label>
      <ScaleCheckbox @update="changeScale" modal :default="item.scale" />
    </div>

    <div class="form-item input">
      <label for="description">Описание</label>
      <textarea
        id="description"
        class="h-100px"
        ref="description"
        placeholder="Описание"
        :value="item.description"
        @keypress.enter.stop
      ></textarea>
    </div>

    <div class="form-item input">
      <label for="photos1">Фотографии</label>
      <input
        type="text"
        id="photos1"
        :value="item.photos[0]"
        ref="photos1"
        placeholder="Фото 1"
      />
    </div>

    <div class="form-item input">
      <input
        type="text"
        id="photos2"
        :value="item.photos[1]"
        ref="photos2"
        placeholder="Фото 2"
      />
    </div>

    <div class="form-item input">
      <input
        type="text"
        id="photos3"
        :value="item.photos[2]"
        ref="photos3"
        placeholder="Фото 3"
      />
    </div>
  </app-modal>
</template>

<script>
import AppModal from '@/components/ui/AppModal'
import ScaleCheckbox from '@/components/ui/ScaleCheckbox'

export default {
  name: 'EditModal',
  components: { AppModal, ScaleCheckbox },
  emits: ['close', 'action'],
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: function(value) {
        return ['book', 'film', 'song', 'game'].indexOf(value) !== -1
      }
    }
  },
  data() {
    return {
      scale: '0'
    }
  },
  watch: {
    item() {
      this.scale = this.item.scale
    }
  },
  updated() {
    if (this.$refs.name) {
      this.$refs.name.focus()
    }
  },
  methods: {
    changeScale(value) {
      this.scale = value
    },
    save() {
      this.$emit('action', {
        id: this.item.id,
        name: this.$refs.name?.value,
        place: this.$refs.place?.value,
        release: this.$refs.release?.value,
        country: this.$refs.country?.value,
        points: this.$refs.points?.value,
        genre: this.$refs.genre?.value,
        scale: this.scale,
        description: this.$refs.description?.value,
        photos: [
          this.$refs.photos1.value,
          this.$refs.photos2.value,
          this.$refs.photos3.value
        ]
      })
    }
  }
}
</script>
